import sidebarBg from "./assets/sidebar-bg.svg";
import { useStore } from "./index";
import { observer } from "mobx-react-lite";

const ClosedSidebar = observer(() => {
    // Stores
    const { sidebarStore } = useStore();

    return (
        <>
            <div className="h-screen flex flex-col justify-between items-center w-[50px] fixed top-0 left-0 bg-gradient-to-tr from-black via-blue-700 to-purple-800 backdrop-blur-2xl bg-opacity-70 "
                style={{
                    backgroundImage: `url(${sidebarBg})`,
                    backgroundSize: "100% 100%",
                }}
            >
                <div className="w-full pt-2  h-screen flex flex-col bg-white bg-opacity-10 border border-gray-400 justify-between items-center rounded-tl-lg rounded-tb-lg backdrop-filter backdrop-blur-md  border-opacity-30">
                    <div
                        className="w-fit h-fit border border-transparent hover:border-white rounded-lg duration-300 cursor-pointer transition"
                        onClick={() => sidebarStore.toggleSidebar()}
                    >
                        <img src="/icons/burger.svg" alt="Search" width={"40px"} />
                    </div>

                </div>
            </div>
        </>
    );
});

export default ClosedSidebar;