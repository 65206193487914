import React, { useCallback, useEffect, useState } from "react";
import { encode, decode } from "html-entities";
import sampleHTML from "../assets/json/html.json";
import CopyButton from "../components/CopyButton";

export default function HTMLEntityConverter() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [isEncoding, setIsEncoding] = useState(true);

  const handleInputChange = (e) => {
    const newText = e.target.value;
    setInputText(newText);
  };

  const convertText = useCallback(
    (text) => {
      try {
        if (isEncoding) {
          const encoder = encode(text);
          setOutputText(encoder);
        } else {
          const decoder = decode(text);
          setOutputText(decoder);
        }
      } catch (error) {
        setOutputText(isEncoding ? "Error encoding text" : "Invalid Base64 string");
      }
    },
    [isEncoding]
  );

  const handleClipboardText = async () => {
    const clipboardText = await navigator.clipboard.readText();
    setInputText(clipboardText);
    convertText(clipboardText);
  };

  const handleSample = async () => {
    setInputText(isEncoding ? sampleHTML[0] : decode(sampleHTML[0]));
    convertText(isEncoding ? sampleHTML[0] : decode(sampleHTML[0]));
  };

  const handleClear = async () => {
    setInputText("");
    setOutputText("");
  };

  const handleUseAsInput = async () => {
    setInputText(outputText);
    convertText(outputText);
  };

  useEffect(() => {
    convertText(inputText);
  }, [inputText, isEncoding, convertText]);

  return (
    <div className="h-full flex">
      {/* Main Content */}
      <div className="w-full px-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">HTML Entity {isEncoding ? "Encode" : "Decode"}</div>
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                <div className="inline-block">Input:</div>
                <button className="btn btn-xs mx-1" onClick={handleClipboardText}>
                  Clipboard
                </button>
                <button className="btn btn-xs  mr-1" onClick={handleSample}>
                  Sample
                </button>
                <button className="btn btn-xs  mr-1" onClick={handleClear}>
                  Clear
                </button>
              </div>
              <div className="flex">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input type="radio" name="radio-10" checked={isEncoding} onChange={() => setIsEncoding(true)} className="radio checked:bg-blue-500" />
                    <span className="label-text">Encode</span>
                  </label>
                </div>
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input type="radio" name="radio-10" checked={!isEncoding} onChange={() => setIsEncoding(false)} className="radio checked:bg-blue-500" />
                    <span className="label-text">Decode</span>
                  </label>
                </div>
              </div>
            </div>
            <textarea
              placeholder={`Enter text to ${isEncoding ? "Encode" : "Decode"} here`}
              value={inputText}
              onChange={handleInputChange}
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[37vh] max-h-[78.5vh] text-white"
            />
          </div>
          <div>
            <div className="flex justify-between mb-3">
              <p className="py-1">Output:</p>
              <div className="flex gap-3">
                <button className="btn btn-xs" onClick={handleUseAsInput}>
                  use as input ⏫
                </button>
                <CopyButton text={outputText} />
              </div>
            </div>
            <textarea
              placeholder={`${isEncoding ? "Decode" : "Encode"} text will appear here`}
              value={outputText}
              className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[37vh] max-h-[78.5vh] text-white"
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
}
