import {useEffect, useState} from "react";
import CodeMirror from "@uiw/react-codemirror";
import {EditorView} from "codemirror";
import {dracula} from "@uiw/codemirror-theme-dracula";
import axios from "axios";
import prompts from "../assets/json/llm_code_converter_prompt.json";
import toast from "react-hot-toast";
import {copyToClipboard} from "../components/CopyButton";

// Load syntax highlighter
import {javascriptLanguage} from '@codemirror/lang-javascript';
import {typescriptLanguage} from '@codemirror/lang-javascript';
import {jsonLanguage} from '@codemirror/lang-json';
import {phpLanguage} from '@codemirror/lang-php';
import {markdownLanguage} from '@codemirror/lang-markdown';
import {htmlLanguage} from '@codemirror/lang-html';


export const getLangs = (name: string) => {
  const langs: {[key: string]: any} = {
    'json': jsonLanguage,
    'php': phpLanguage,
    'javascript': javascriptLanguage,
    'typescript': typescriptLanguage,
    'markdown': markdownLanguage,
    'html': htmlLanguage,
  }
  return langs[name]
}

function LLMCodeConverter() {
  // State
  const [inputText, setInputText] = useState("");
  const [model, setModel] = useState("hf-mistral-7b-instruct-v0.3");
  const [resultText, setResultText] = useState("");
  const [selectedPromptIndex, setSelectedPromptIndex] = useState(0);
  const [currentCustomPrompt, setCurrentCustomPrompt] = useState('')
  const [isLlmLoading, setIsLlmLoading] = useState(false);

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error paste from clipboard: " + error);
    }
  };

  const handleSubmit = async (props: any) => {
    if (props.inputText === '') {
      toast.error('Input can\'t be null');
      return;
    }
    if (props.prompt === '') {
      toast.error('Prompt can\'t be null');
      return;
    }

    // Compose prompt
    let finalPrompt = props.prompt ?? '';
    setIsLlmLoading(true);

    // Compose system instruction
    if (props.model === 'hf-mistral-7b-instruct-v0.3') {
      let mistralInstruction = '';

      props.prompts.shot_learning.forEach((prompt: any) => {
        mistralInstruction += '<s>[INST] ' + finalPrompt.replace('{{input}}', atob(prompt.user)) +
          ' [/INST] ' + atob(prompt.assistant) + '</s>';
      });

      mistralInstruction += '[INST] ' +
        finalPrompt.replace('{{input}}', props.input) + '[/INST]'
      console.log(mistralInstruction)

      const fetchData = async () => {
        try {
          const response = await axios.post(
            "https://huedaya.com/api/llm/generate",
            {
              "prompt": mistralInstruction,
              "history": [],
              "system_prompt": "You are AI assistant that very good at programming, " +
                "your fluency level is expert, so do not explain code too much.",
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer kuAPpC7Ge%4zH5&b#g&FhNxA^E%eMrQb*x8E*mYhqp"
              },
            },
          );
          if (response.data.data.response) {
            var trimmedResult = response.data.data.response;
            trimmedResult = trimmedResult.replace(/<\/s>$/, '');
            trimmedResult = trimmedResult.replace(/^ ```php\s*/, '');
            trimmedResult = trimmedResult.replace(/^```php\s*/, '');
            trimmedResult = trimmedResult.replace(/```$/, '');
            trimmedResult = trimmedResult.trimLeft();
            setResultText(trimmedResult)
          } else {
            toast.error(response.data.error)
          }
          setIsLlmLoading(false);

          // Load timer from local storage 
          if (typeof (Storage) !== "undefined") {
            var llmExecutionTime = localStorage.getItem('llm_execution_time');
            toast.success('Done in ' + (parseInt(llmExecutionTime) / 1000).toFixed(1) + 's');
          } else {
            toast.success('Done!');
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
          setIsLlmLoading(false);
        }
      };
      fetchData();
    }
    else if (
      props.model === 'groq-llama3-70b-8192' ||
      props.model === 'groq-llama3-8b-8192' ||
      props.model === 'groq-gemma2-9b-it' ||
      props.model === 'groq-gemma-7b-it' ||
      props.model === 'groq-mixtral-8x7b-32768'
    ) {
      const prompt = currentCustomPrompt.replace('{{input}}', props.input);

      const fetchData = async () => {
        try {
          const response = await axios.post(
            'https://groq1.huedaya.com/api/completion',
            {
              "model": props.model.replace('groq-', ''),
              "messages": [
                {
                  "content": "You are AI assistant that very good at programming, " +
                    "your fluency level is expert, so do not explain code too much",
                  "role": "system"
                },
                {
                  "content": prompt,
                  "role": "user"
                }
              ],
              "temperature": 0.2,
              "max_tokens": 2048,
              "top_p": 1,
              "stream": false
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            },
          );

          if (response.data.choices.length > 0) {
            var trimmedResult = response.data.choices[0].message.content;
            trimmedResult = trimmedResult.replace(/<\/s>$/, '');
            trimmedResult = trimmedResult.replace(/^ ```php\s*/, '');
            trimmedResult = trimmedResult.replace(/^```php\s*/, '');
            trimmedResult = trimmedResult.replace(/^ ```json\s*/, '');
            trimmedResult = trimmedResult.replace(/^```json\s*/, '');
            trimmedResult = trimmedResult.replace(/^ ```tsx\s*/, '');
            trimmedResult = trimmedResult.replace(/^```tsx\s*/, '');
            trimmedResult = trimmedResult.replace(/^ ```jsx\s*/, '');
            trimmedResult = trimmedResult.replace(/^```jsx\s*/, '');
            trimmedResult = trimmedResult.replace(/^ ```svg\s*/, '');
            trimmedResult = trimmedResult.replace(/^```svg\s*/, '');
            trimmedResult = trimmedResult.replace(/^ ```javascript\s*/, '');
            trimmedResult = trimmedResult.replace(/^```javascript\s*/, '');
            trimmedResult = trimmedResult.replace(/```$/, '');
            trimmedResult = trimmedResult.trimLeft();
            setResultText(trimmedResult)
          } else {
            toast.error(response.data.error)
          }
          setIsLlmLoading(false);

          // Load timer from local storage 
          if (typeof (Storage) !== "undefined") {
            var llmExecutionTime = localStorage.getItem('llm_execution_time');
            toast.success('Done in ' + (parseInt(llmExecutionTime) / 1000).toFixed(1) + 's');
          } else {
            toast.success('Done!');
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error(error);
          setIsLlmLoading(false);
        }
      };
      fetchData();
    }

    else {
      toast.error('Unknown LLM model');
    };
  }

  useEffect(() => {
    setCurrentCustomPrompt(prompts[selectedPromptIndex].prompt.toString())
    setInputText(atob(prompts[selectedPromptIndex].sample_code).toString())
  }, [selectedPromptIndex])

  const [loadingTime, setLoadingTime] = useState(0);

  useEffect(() => {
    let timer;

    if (isLlmLoading) {
      timer = setInterval(() => {
        setLoadingTime(prevTime => prevTime + 100);

      }, 100);
    } else {
      setLoadingTime(0); // Reset loading time when loading is complete
      clearInterval(timer); // Clear interval when loading is complete
    }

    // Clear the interval when component unmounts or when loading is done
    return () => clearInterval(timer);
  }, [isLlmLoading]); // Re-run effect whenever isLlmLoading changes

  useEffect(() => {
    if (typeof (Storage) !== "undefined") {
      localStorage.setItem('llm_execution_time', loadingTime.toString());
    }
  }, [loadingTime])

  return (
    <div className="flex">
      <div className="w-full mx-6 ">
        <div className="w-full flex items-center font-bold mb-3">
          Magic Code Converter
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="mb-2">
              <div className="grid grid-cols-2 gap-0">
                <div>
                  <div className="inline-block mr-2">Input</div>
                  <button
                    className="btn btn-xs"
                    onClick={handlePasteFromClipboard}
                  >
                    Clipboard
                  </button>
                </div>

                <div className=" justify-end">
                  <select className="select select-xs select-bordered w-full btn btn-xs mx-0"
                    value={selectedPromptIndex}
                    onChange={(e) => setSelectedPromptIndex(parseInt(e.target.value))} >
                    {
                      prompts.map((item, index) => {
                        return <option key={`prompt-${index}`} value={index}>{prompts[index].label}</option>
                      })
                    }
                  </select>

                </div>
              </div>
            </div>
            <CodeMirror
              className="bg-gray-900 w-full border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[84vh] max-h-[84vh]"
              placeholder="Enter input here"
              value={inputText}
              onChange={(e) => setInputText(e)}
              height="84vh"
              theme={dracula}
              extensions={[
                EditorView.editable.of(true),
                EditorView.lineWrapping,
                getLangs(prompts[selectedPromptIndex].sample_lang ?? 'markdown')
              ]}
            />
          </div>
          <div>

            <div className="flex justify-between">
              <div className="">Custom Prompt</div>
              <div className="inline-block ml-auto">
                <div>
                  <select className="select select-xs select-bordered w-full "
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  >
                    <option value={'hf-mistral-7b-instruct-v0.3'}>HuggingFace: Mistral 7B v0.3</option>
                    {/* Disable groq because maintenance */}
                    {/* <option value={'groq-llama3-8b-8192'}>Groq: llama3-70b-8192</option>
                    <option value={'groq-llama3-8b-8192'}>Groq: llama3-8b-8192</option>
                    <option value={'groq-gemma2-9b-it'}>Groq: gemma2-9b-it</option>
                    <option value={'groq-gemma-7b-it'}>Groq: gemma-7b-it</option>
                    <option value={'groq-mixtral-8x7b-32768'}>Groq: mixtral-8x7b-32768</option> */}
                  </select>
                </div>
              </div>
            </div>

            <textarea
              rows={3}
              value={currentCustomPrompt}
              onChange={(e) => setCurrentCustomPrompt(e.target.value)}
              className="mt-2 text-xs bg-gray-900 w-full p-1 mb-2 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"></textarea>
            <div>
              <div className="flex justify-between mb-2">
                <div>Result</div>
                <div className="inline-block ml-auto">
                  <button className="btn btn-xs mx-1"
                    disabled={isLlmLoading}
                    onClick={() => {
                      handleSubmit({
                        model: model,
                        prompt: currentCustomPrompt,
                        input: inputText,
                        prompts: prompts[selectedPromptIndex]
                      });
                    }}
                  >
                    {
                      isLlmLoading ? (
                        <>Loading {(loadingTime / 1000).toFixed(1)}s</>
                      ) : (
                        <>Convert</>
                      )
                    }
                  </button>
                  <button className="btn btn-xs mx-1" onClick={() => {copyToClipboard(resultText)}}>Copy</button>
                </div>
              </div>
              <CodeMirror
                className="bg-gray-900 w-full border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[68.9vh] max-h-[68.9vh]"
                extensions={[
                  EditorView.editable.of(true),
                  EditorView.lineWrapping,
                  getLangs(prompts[selectedPromptIndex].result_lang ?? 'markdown')
                ]}
                theme={dracula}
                value={resultText}
                height="68.9vh"
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default LLMCodeConverter;
