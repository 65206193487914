import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react";
import CopyButton from "../components/CopyButton";
import { toast } from "react-hot-toast";

import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker";


function UnixTimeConverter() {
  // States
  const [selectedMode, setSelectedMode] = useState('sec');
  const [inputFieldValue, setInputFieldValue] = useState(Math.floor(moment().valueOf() / 1000));
  const [timestamp, setTimestamp] = useState(inputFieldValue);

  // Functions
  const handleTimestampChange = (event) => {
    let value = event.target.value;
    setInputFieldValue(value)
  };

  useEffect(() => {
    let value = inputFieldValue;

    // Select mode
    if (selectedMode === 'msec') {
      value = (value / 1000)
    }

    setTimestamp(value);
  }, [inputFieldValue, selectedMode])

  const handleNowClick = () => {
    // Select mode
    if (selectedMode === 'msec') {
      setInputFieldValue(Math.floor(moment().valueOf()));
    } else {
      setInputFieldValue(Math.floor(moment().valueOf() / 1000));
    }
  };

  const handleClipboardClick = () => {
    // Read the clipboard value and set the timestamp to that value (assuming it's a valid Unix timestamp)
    const clipboardValue = window.navigator.clipboard.readText();
    clipboardValue.then((value) => {
      const unixTimestamp = parseInt(value);
      if (!isNaN(unixTimestamp)) {
        setInputFieldValue(unixTimestamp);
        toast.success("Copied from Clipboard", {
          duration: 1500, // in ms
        });
      }
    });
  };

  const handleClearClick = () => {
    // Set the timestamp to null or any other default value you prefer
    setInputFieldValue(null);
  };

  const calculateRelativeDate = () => {
    if (timestamp) {
      const inputUnixTime = moment.unix(Number(timestamp));
      const currentUnixTime = moment();
      const relativeDuration = moment.duration(inputUnixTime.diff(currentUnixTime));
      return relativeDuration.humanize(); // Menggunakan built-in moment untuk menghitung perbedaan waktu
    }
    return ""; // Atau nilai default yang sesuai dengan kasus Anda
  };

  const localDateTimeFormats = [
    {
      format: "dddd, MMM DD, YYYY",
      label: "Other Formats (Local)",
    },
    {
      format: "DD/MM/YYYY",
    },
    {
      format: "YYYY-MM-DD",
    },
    {
      format: "DD-MM-YYYY hh:mm",
    },
    {
      format: "MMM DD, hh:mm A",
    },
    {
      format: "MMMM YYYY",
    },
    {
      format: "MMM DD, YYYY",
    },
    {
      format: "HH:mm:ss.SSS",
    },
  ];

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
    <button className="btn btn-xs " onClick={onClick} ref={ref}>
      DATE PICKER: {value}
    </button>
  ));


  return (
    <div className="w-full px-6 my-2 ">
      <div className="w-full flex items-center font-bold mb-3">Unix Time Converter</div>
      <div>
        <div>
          <div className="mb-2">
            <div className="mb-2 inline-block">Input:</div>
            <div className="inline-block ml-3">
              <button onClick={handleNowClick} className="btn btn-xs mr-1">
                Now
              </button>
              <button onClick={handleClipboardClick} className="btn btn-xs mr-1">
                Clipboard
              </button>
              <button onClick={handleClearClick} className="btn btn-xs mr-1">
                Clear
              </button>

              <DatePicker
                showTimeSelect
                selected={moment.unix(Number(timestamp)).toDate()}
                onChange={(date: Date) => {
                  const unixTimestamp = moment(date).unix();
                  setTimestamp(unixTimestamp);
                }}
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<ExampleCustomInput />}
              />
            </div>

          </div>
          <div className="flex gap-3 items-center">
            <input
              type="number"
              placeholder="Input Unix Time Here"
              value={inputFieldValue || ""}
              onChange={handleTimestampChange}
              className="bg-gray-900 w-96 px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
            />
            <select className="select select-bordered w-full max-w-xs" value={selectedMode} onChange={(e) => setSelectedMode(e.target.value)}>
              <option key={1} value={'sec'}>
                Unix time (seconds since epoch)
              </option>

              <option key={2} value={'msec'}>
                Unix time (micro-seconds since epoch)
              </option>
            </select>
          </div>
        </div>
        <br />
        <hr className=" mx-auto mr-12" />
        <div>
          <div className="grid grid-cols-2 h-full mt-4 min-[1450px]:grid-cols-3">
            <div className="justify-self-stretch">
              <div className="mt-2 mb-4">
                <div className="mb-2">Local:</div>
                <div className="flex items-center">
                  <input
                    className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                    placeholder="Local Date"
                    // value={localConvertedData ? `${localConvertedData.localDate} ${localConvertedData.localYear}` : ''}
                    value={timestamp ? moment.unix(Number(timestamp)).format("ddd MMM DD HH:mm:ss ZZ YYYY") : "-"}
                    readOnly
                  />
                  <CopyButton text={timestamp ? moment.unix(Number(timestamp)).format("ddd  MMM DD HH:mm:ss ZZ YYYY") : "-"} />
                </div>
              </div>

              <div className="mb-2">UTC (ISO 8601):</div>
              <div className="flex items-center">
                <input
                  className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                  placeholder="UTC Date"
                  value={timestamp ? moment.unix(Number(timestamp)).format("YYYY-MM-DDTHH:mm:ss[Z]") : "-"}
                  readOnly
                />
                <CopyButton text={timestamp ? moment.unix(Number(timestamp)).format() : "-"} />
              </div>
              <div>
                <div className="mb-2 mt-4">Relative Date:</div>
                <div className="flex items-center">
                  <input
                    className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                    placeholder="Relative Date"
                    value={calculateRelativeDate()}
                    readOnly
                  />
                  <CopyButton text={calculateRelativeDate()} />
                </div>
              </div>
              <div>
                <div className="mb-2 mt-4">Unix Time:</div>
                <div className="flex items-center">
                  <input
                    className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                    placeholder="Unix Time" value={timestamp} readOnly />
                  <CopyButton text={timestamp} />
                </div>
              </div>
            </div>

            <div className="justify-self-center px-4">
              <div className="mb-2 mt-2">Day of year</div>
              <div className="flex items-center">
                <input
                  className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                  placeholder="Day of year"
                  value={timestamp ? moment.unix(timestamp).dayOfYear() : "-"}
                  readOnly
                />
                <CopyButton text={timestamp ? moment.unix(timestamp).dayOfYear() : "-"} />
              </div>

              <div className="mb-2 mt-4">Week of year</div>
              <div className="flex items-center">
                <input
                  className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                  placeholder="Week of year"
                  value={timestamp ? moment.unix(timestamp).week() : "-"}
                  readOnly
                />
                <CopyButton text={timestamp ? moment.unix(timestamp).week() : "-"} />
              </div>

              <div className="mb-2 mt-4">Is leap year?</div>
              <div className="flex items-center">
                <input
                  className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                  placeholder="Is leap year"
                  value={timestamp ? moment.unix(timestamp).isLeapYear().toString() : "-"}
                  readOnly
                />
                <CopyButton text={timestamp ? moment.unix(timestamp).isLeapYear().toString() : "-"} />
              </div>
            </div>

            <div className="justify-self-stretch">
              {localDateTimeFormats.map((formatObj) => (
                <div key={formatObj.format}>
                  <div className="mb-2 mt-2">{formatObj.label}</div>
                  <div className="flex items-center">
                    <input
                      className="max-w-[384px] w-full input input-bordered input-sm mr-2"
                      placeholder={formatObj.label}
                      value={timestamp ? moment.unix(Number(timestamp)).format(formatObj.format) : "-"}
                      readOnly
                    />
                    <CopyButton text={timestamp ? moment.unix(Number(timestamp)).format(formatObj.format) : "-"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnixTimeConverter;
