import { makeAutoObservable } from "mobx";

export class SidebarStore {
    isOpen: boolean = true;

    constructor() {
        makeAutoObservable(this);
    }

    toggleSidebar = () => {
        this.isOpen = !this.isOpen;
    };
}
