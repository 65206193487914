import React, { useState, useRef, useEffect } from "react";

const SMTPMailDev: React.FC = () => {
  const [emails, setEmails] = useState([
    {
      id: 1,
      sender: "sender@example.com",
      subject: "Reset Password",
      time: "3 seconds ago",
    },
    {
      id: 2,
      sender: "sender@example.com",
      subject: "Email Subject lorem ipsum dolor...",
      time: "1 minute ago",
    },
  ]);

  const [selectedEmail, setSelectedEmail] = useState(emails[0] || null);
  const [newSender, setNewSender] = useState(""); // State untuk input sender baru
  const modalRef = useRef<HTMLDialogElement>(null);

  // State untuk "Last update"
  const [lastUpdate, setLastUpdate] = useState("3 seconds ago");

  // Update Last update setiap 3 detik
  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdate("Just now");
    }, 3000); // Setiap 3 detik

    return () => clearInterval(interval); // Clear interval saat komponen dibongkar
  }, []);

  // Fungsi untuk menghapus email
  const deleteEmail = (id: number) => {
    setEmails(emails.filter((email) => email.id !== id));
    if (selectedEmail?.id === id) {
      setSelectedEmail(emails.length > 1 ? emails[1] : null);
    }
  };

  // Fungsi untuk membersihkan semua email
  const clearEmails = () => {
    setEmails([]);
    setSelectedEmail(null);
  };

  // Fungsi untuk membuka modal
  const openModal = () => {
    modalRef.current?.showModal();
  };

  // Fungsi untuk menutup modal
  const closeModal = () => {
    modalRef.current?.close();
  };

  // Fungsi untuk menangani klik di luar modal
  const handleOutsideClick = (event: React.MouseEvent<HTMLDialogElement>) => {
    if (modalRef.current && event.target === modalRef.current) {
      closeModal();
    }
  };

  // Fungsi untuk menambahkan email baru ke daftar emails
  const addEmail = () => {
    if (newSender.trim() === "") return; // Validasi: pastikan input tidak kosong
    const newEmail = {
      id: emails.length + 1, // ID baru yang unik
      sender: newSender,
      subject: "New email subject", // Subject default
      time: "Just now", // Waktu default
    };
    setEmails([...emails, newEmail]);
    setNewSender(""); // Reset input sender
  };

  return (
    <div className="p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">SMTP Mail Dev</h1>
      </div>

      {/* Tombol Settings dan Clear */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <button className="btn btn-sm" onClick={openModal}>
            Settings
          </button>
          <button className="btn btn-sm" onClick={clearEmails}>
            Clear
          </button>
          <span className="ml-2 text-sm">Last update {lastUpdate}</span>
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="recipient" className="font-bold text-sm">
            Recipient:
          </label>
          <input
            type="text"
            id="recipient"
            placeholder="me@example.com"
            className="input input-bordered input-sm w-full max-w-xs"
            style={{ color: "gray" }}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Kolom kiri: List email dengan border besar di sekeliling */}
        <div className="w-1/4 border p-4 h-96 overflow-y-auto rounded-lg">
          <div className="h-full">
            {/* Daftar email dengan border per item */}
            {emails.length > 0 ? (
              emails.map((email) => (
                <div
                  key={email.id}
                  className={`p-2 mb-2 border border-gray-300 rounded-lg flex justify-between items-center cursor-pointer ${
                    selectedEmail?.id === email.id
                      ? "bg-gray-200 bg-opacity-50"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => setSelectedEmail(email)}
                >
                  <div className="text-xs">
                    <p className="font-bold">{email.sender}</p>
                    <p>{email.subject}</p>
                  </div>
                  <div className="flex flex-col items-center space-y-1">
                    <p className="text-right text-[10px] text-gray-500 whitespace-nowrap">
                      {email.time}
                    </p>
                    <div className="w-full flex justify-end">
                      <button onClick={() => deleteEmail(email.id)}>
                        <img
                          src="/icons/delete.svg"
                          alt="delete"
                          className="h-3 w-3 text-gray-500 hover:text-gray-500"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No emails</p>
            )}
          </div>
        </div>

        {/* Kolom kanan: Detail email */}
        <div className="w-3/4 p-4 border rounded-lg">
          {selectedEmail ? (
            <div>
              <div className="mb-4">
                <p>
                  <strong>Sender:</strong> {selectedEmail.sender}
                </p>
                <p>
                  <strong>Subject:</strong> {selectedEmail.subject}
                </p>
                <p>
                  <strong>Timestamp:</strong> {selectedEmail.time}
                </p>
              </div>
              <div className="-mx-4 border-t my-4"></div>
              <div className="h-64 flex items-center justify-center">
                <p>Email Content Here</p>
              </div>
            </div>
          ) : (
            <p className="text-center">No email selected</p>
          )}
        </div>
      </div>

      {/* Modal untuk Settings */}
      <dialog
        ref={modalRef}
        className="modal z-50"
        onClick={handleOutsideClick}
      >
        <div className="modal-box">
          <h3 className="text-black text-lg">Settings</h3>

          {/* Tablist menggunakan DaisyUI */}
          <div role="tablist" className="tabs tabs-lifted">
            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Sender"
              defaultChecked
            />
            <div
              role="tabpanel"
              className="tab-content bg-base-100 border-base-300 rounded-box p-6"
            >
              {/* Input Sender */}
              <div className="flex items-center mb-4">
                <input
                  type="text"
                  className="input input-bordered flex-grow mr-2"
                  placeholder="sender@example.com"
                  value={newSender}
                  onChange={(e) => setNewSender(e.target.value)} // Update state input
                  style={{ color: "gray" }}
                />
                <button
                  className="btn btn-ghost"
                  onClick={() => setNewSender("")} // Clear input on click
                >
                  <img
                    src="/icons/delete.svg"
                    alt="delete"
                    className="h-5 w-5"
                  />
                </button>
              </div>

              {/* Tombol Add di bawah input sender */}
              <div className="mt-2">
                <button className="btn btn-sm">Add</button>
              </div>

              {/* Tombol Save */}
              <div className="flex justify-start mt-4">
                <button className="btn btn-sm" onClick={addEmail}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default SMTPMailDev;
//
