import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { copyToClipboard } from "../components/CopyButton";
import toast from "react-hot-toast";
import { useDropzone, FileWithPath } from "react-dropzone";
import { EditorView } from "codemirror";
import { jsonLanguage } from "@codemirror/lang-json";

export default function Postman() {
  const [Content, setContent] = useState("");
  const [isRemoveUnusedEnabled, setIsRemoveUnusedEnabled] = useState(false);
  const [isRedactEmailEnabled, setIsRedactEmailEnabled] = useState(false);
  const [newRedactEmail, setNewRedactEmail] = useState<string>("user@example.com");
  const [originalContent,setOriginalContent] = useState("");

  useEffect (() => {
    if (Content && !originalContent) {
      setOriginalContent(Content);
    }
  }, [Content, originalContent]);

  useEffect (() => {
    let updatedContent = originalContent;
    if (isRedactEmailEnabled) {
      updatedContent = replaceEmail(updatedContent, newRedactEmail);
    } 

    if (isRemoveUnusedEnabled) {
      // Remove specific objects in 'info'
      try {
        const parsedContent = JSON.parse(updatedContent);
        delete parsedContent.info._postman_id;
        delete parsedContent.info._collection_link;
        delete parsedContent.info._exporter_id;

        updatedContent = JSON.stringify(parsedContent, null, 4);
      } catch (e) {
        console.error("Error", e);

        toast.error("Error reading JSON: " + e, {
          duration: 1500, // in ms
        });
      }
    } 
    setContent(updatedContent);
  }, [isRedactEmailEnabled, isRemoveUnusedEnabled,newRedactEmail, originalContent]);

  const replaceEmail = (input: string, newEmail: string) => {
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)(?<!\.)/gi;
    return input.replace(emailRegex, newEmail);
  };

  const downloadFile = (myData: string, fileName: string) => {
    const blob = new Blob([myData], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    // Function to read the content of each uploaded file
    const readFileContents = async () => {
      for (const file of acceptedFiles) {
        const reader = new FileReader();
        reader.onload = () => {
          setContent(reader.result?.toString() || "");
        };
        // Read the file as text
        reader.readAsText(file);
      }
    };

    if (acceptedFiles.length > 0) {
      readFileContents();
    }
  }, [acceptedFiles]);

  const files = acceptedFiles.map((file: FileWithPath) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">
          Postman Exporter
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex mb-2">
              <div className="mb-2 inline-block">Content</div>
              <div className="inline-block ml-3">
                <button
                  className="btn btn-xs mx-1"
                  onClick={() => {
                    downloadFile(Content, "api.postman_collection");
                  }}
                >
                  Download
                </button>
                <button
                  className="btn btn-xs mx-1"
                  onClick={() => {
                    copyToClipboard(Content);
                  }}
                  onChange={(e) => setContent(Content)}
                >
                  Copy
                </button>
              </div>
            </div>
            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh]"
              theme={dracula}
              height="78.5vh"
              value={Content}
              onChange={setContent}
              extensions={[
                EditorView.lineWrapping,
                jsonLanguage,
              ]}
            />
          </div>
          <div className="">
            <div>
              <div className="flex justify-between mb-2">
                <div className="mb-2">Upload</div>
              </div>

              <div {...getRootProps()}>
                <input {...getInputProps()} />

                <div className="bg-gray-900 w-full  border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 h-44 flex flex-col items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  <div>Drag Postman File</div>
                  <ul>{files}</ul>
                </div>
              </div>

              <p className="mt-5">Options</p>
              <div
                className="flex mt-2 cursor-pointer"
                onClick={() => setIsRemoveUnusedEnabled(!isRemoveUnusedEnabled)}
              >
                <input
                  checked={isRemoveUnusedEnabled}
                  type="checkbox"
                  className="w-5"
                />
                <p className="ml-2">Remove Unused Header</p>
              </div>
              <div
                className="flex mt-2 cursor-pointer"
                onClick={() => setIsRedactEmailEnabled(!isRedactEmailEnabled)}
              >
                <input
                  checked={isRedactEmailEnabled}
                  type="checkbox"
                  className="w-5"
                />
                <p className="ml-2">Replace E-Mail</p>
              </div>
              {isRedactEmailEnabled && (
                <input
                  type="text"
                  value={newRedactEmail}
                  placeholder="Type Email"
                  onChange={(e) => setNewRedactEmail(e.target.value)}
                  className="input input-bordered w-96 mt-2 h-10"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}