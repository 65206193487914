import {makeAutoObservable, runInAction, toJS} from "mobx";
import axios from "axios";

interface GroqMessage {
    content: string;
    role: string;
}

interface HuggingFaceMistralInstruction {
    prompt: string;
    type: string;
}

export class LlmStore {
    // Config 
    groqApiEndpoint: string = 'https://groq1.huedaya.com/api/completion';
    huggingFaceEndpoint: string = 'https://huedaya.com/api/llm/generate';
    huggingFaceApiKey: string = 'kuAPpC7Ge%4zH5&b#g&FhNxA^E%eMrQb*x8E*mYhqp';

    // State
    isLlmLoading: boolean = false;
    isLlmSuccess: boolean = true;
    modelIndex: any = 0;
    availableModels: string[] = [
        // 'groq-llama-3.1-8b-instant', // disable because the groq server in maintenance
        // 'groq-llama-3.1-70b-versatile',
        // 'groq-llama-3.1-405b-reasoning',
        // 'groq-llama3-70b-8192',
        // 'groq-llama3-8b-8192',
        // 'groq-gemma2-9b-it',
        // 'groq-gemma-7b-it',
        // 'groq-mixtral-8x7b-32768',
        'hf-mistral-7b-instruct-v0.3',
    ];
    executionTime: number = 0;
    errorMessage: string = '';
    resultText: string = '';
    temperature: number = 0.2;
    maxTokens: number = 2048;
    isCodeOnlyResult: boolean = true;
    groqConversational: GroqMessage[] = [
        {
            content: 'You are AI assistant that very good at programming, your fluency level is expert, so do not explain code too much. Do not include alternative answers. Do not explain the code, do not include comments. Do not include texts like Here is the React component code: just code. Write it in proper indention and clean code variable naming. Dont use third-party library',
            role: 'system'
        },
        {
            content: 'Write sample react program', // User prompted model
            role: 'user'
        }
    ];
    huggingFaceMistralInstruction: HuggingFaceMistralInstruction[] = [
        {
            prompt: 'Write PHP code to show hello world, show code only',
            type: 'instruction'
        },
        {
            prompt: '<?php echo "Hello World!"; ?>',
            type: 'result'
        }
    ];
    huggingFaceSystemPrompt: string = 'You are AI assistant that very good at programming, your fluency level is expert, so do not explain code too much. Do not include alternative answers. Do not explain the code, do not include comments. Do not include texts like Here is the React component code: just code. Write it in proper indention and clean code variable naming. Dont use third-party library';

    private updateInterval: any = null; // Interval ID for updating execution time

    constructor() {
        makeAutoObservable(this);
        this.llmHandleSubmit = this.llmHandleSubmit.bind(this);
        this.setModelIndex = this.setModelIndex.bind(this);
    }

    // Action to set error message
    setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
    }

    // Action to set model index
    setModelIndex(modelIndex: number) {
        this.modelIndex = modelIndex;
    }

    // Action to set result text
    setResultText(resultText: string) {
        this.resultText = resultText;
    }

    // Action to set loading state
    setIsLlmLoading(isLoading: boolean) {
        this.isLlmLoading = isLoading;
        if (isLoading) {
            this.startExecutionTimeUpdate();
        } else {
            this.stopExecutionTimeUpdate();
        }
    }

    // Action to update conversational messages
    updateGroqConversational(prompt: string) {
        this.groqConversational[1].content = prompt;
    }

    private startExecutionTimeUpdate() {
        // Update executionTime every second
        this.updateInterval = setInterval(() => {
            runInAction(() => {
                this.executionTime += 100; // Increase by 100 milliseconds
            });
        }, 100);
    }

    private stopExecutionTimeUpdate() {
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
            this.updateInterval = null;
        }
    }

    async llmHandleSubmit(props: any) {
        console.log('Starting LLM handling...');
        console.log('Prompt: ', props.prompt);
        this.setIsLlmLoading(true);

        // Reset executionTime
        this.executionTime = 0;

        // Reject if prompt is empty
        if (props.prompt === '') {
            this.setErrorMessage('Prompt can\'t be null');
            this.setIsLlmLoading(false);
            return;
        }

        // Record start time
        const startTime = Date.now();

        try {
            // Hugging Face
            if (this.availableModels[this.modelIndex] === 'hf-mistral-7b-instruct-v0.3') {
                console.log('Mode: Hugging Face Mistral');

                let formattedMistralInstruction = '';
                this.huggingFaceMistralInstruction.forEach((instruction: any) => {
                    if (instruction.type === 'instruction') {
                        formattedMistralInstruction = '<s> [INST] ' + instruction.prompt + ' [/INST] ';
                    }
                    if (instruction.type === 'result') {
                        formattedMistralInstruction = instruction.prompt + '</s>';
                    }
                });

                formattedMistralInstruction = formattedMistralInstruction + '<s>[INST] ' + props.prompt + '[/INST] ';

                const response = await axios.post(
                    this.huggingFaceEndpoint,
                    {
                        "prompt": formattedMistralInstruction,
                        "history": [],
                        "system_prompt": this.huggingFaceSystemPrompt,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": 'Bearer ' + this.huggingFaceApiKey,
                        },
                    },
                );

                runInAction(() => {
                    if (response.data.data.response) {
                        let result = response.data.data.response;
                        if (this.isCodeOnlyResult === true) {
                            result = this.cleanResult(result);
                        }
                        console.log('Result: ', result);

                        this.isLlmSuccess = true;

                        this.setResultText(result);
                    } else {

                        this.isLlmSuccess = false;
                        this.setErrorMessage(response.data.error);
                    }
                });
            }
            // Groq
            else if (this.availableModels.includes(this.availableModels[this.modelIndex])) {
                console.log('Mode: Groq');

                this.updateGroqConversational(props.prompt);

                console.log('Messages: ', toJS(this.groqConversational));

                const response = await axios.post(
                    this.groqApiEndpoint,
                    {
                        "model": this.availableModels[this.modelIndex].replace('groq-', ''),
                        "messages": this.groqConversational,
                        "temperature": this.temperature,
                        "max_tokens": this.maxTokens,
                        "top_p": 1,
                        "stream": false
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                runInAction(() => {
                    if (response.data.choices.length > 0) {
                        let result = response.data.choices[0].message.content;

                        if (this.isCodeOnlyResult) {
                            result = this.cleanResult(result);
                            console.log('x: ', this.cleanResult(result));
                        }

                        console.log('Result: ', result);

                        this.isLlmSuccess = true;
                        this.setResultText(result);
                    } else {
                        console.log('Error 1: ', response.data.error);
                        this.isLlmSuccess = false;
                        this.setErrorMessage(response.data.error);
                    }
                });
            } else {
                this.isLlmSuccess = false;
                this.setErrorMessage('Unknown LLM model');
            }
        } catch (error) {
            console.log('Error 2: ', error);
            if (error.response) {
                // Extract and log details from the error response
                console.log('Error 2.1: ', error.response.data.error.message); // Log the entire response object

                // Set the error message for display
                this.setErrorMessage(error.response.data.error.message);
            } else {
                this.setErrorMessage(error);
            }
            this.isLlmSuccess = false;
        } finally {
            // Record end time and calculate execution time
            const endTime = Date.now();
            this.executionTime = endTime - startTime;

            this.setIsLlmLoading(false);
        }
    }

    cleanResult(result: string): string {
        return result
            .replace(/```[\s\S]*?```/g, match => match.replace(/```/g, '')) // Remove only the backticks, keep the content
            .replace(/<\/s>$/, '')
            .replace(/^(php|json|tsx|jsx|svg|rust|python|javascript)\s*/, '') // Simplified pattern for various languages
            .replace(/\s*$/, '')
            .trimLeft();
    }
}
