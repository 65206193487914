import React, { useCallback, useEffect, useState, } from "react";
import { useLocation } from "react-router-dom";
import sampleLorem from "../assets/json/lorem.json";
import QRCode from "qrcode";
import CopyButton from "../components/CopyButton";
import toast from "react-hot-toast";

const sizeOptions = [
  { label: "0.5x (500px x 500px)", value: 500, scale: 0.5 },
  { label: "1x (1000px x 1000px)", value: 1000, scale: 1 },
  { label: "2x (2000px x 2000px)", value: 2000, scale: 2 },
];

const templates = {
  "(Select Template)": "",
  URL: "https://www.example.com",
  Email: "mailto:name@example.com?subject=your+subject&body=your+body",
  Phone: "tel:+62111222333444",
  SMS: "sms:+62111222333444",
  Whatsapp: "https://wa.me/62111222333444",
  WiFi: "WIFI:T:WPA;\nS:Taksu Dev 1;\nP:12345678;\nH:;\n;",
};

export default function QRCodeGenerator() {
  const [inputText, setInputText] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [selectedSize, setSelectedSize] = useState(sizeOptions[1].value);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isGoogleDriveLink, setIsGoogleDriveLink] = useState(false);

  

  const generateQRCode = useCallback(() => {
    QRCode.toDataURL(
      inputText,
      {
        width: selectedSize,
        margin: 1,
      },
      (err, newText) => {
        if (err) return console.error(err);
        setQrCode(newText); //update state qr menggunakan url 
      }
    );
  }, [inputText, selectedSize]);

  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(window.location.search); 
    const input = query.get('input');  // Ambil parameter `input`
    console.log("Query Parameter:", input);
    if (input) {
      try {
        // Decode Base64
        const decoded = atob(input);  // Decode Base64
        setInputText(decoded);  // Prefill textarea

      } catch (error) {
        console.error("Error decoding Base64:", error);
      }
    }
  }, [location.search]);


  useEffect(() => {
    generateQRCode();
  }, [inputText, generateQRCode]);
  console.log("Generating QR Code for:", inputText);


  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => {
    const value = e.target.value;
    if (e.target.tagName === "SELECT") {
      setSelectedTemplate(value);
      setInputText(templates[value]);
    } else {
      setInputText(value);
    }
    generateQRCode();
  };


  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error reading from clipboard", error);
    }
  };

  const handleSampleFromJson = async () => {
    setInputText(sampleLorem[0]);
  };

  const handleClear = async () => {
    setInputText("");
  };

  //copyy base64content to clipboard
  const handleCopyURL = async () => {
    const base64Content = btoa(inputText);
    const directURL = `https://devtool.internal.taksu.tech/qr-code-generator?input=${base64Content}`;

    //condition to copy url
    if (navigator.clipboard) {
      navigator.clipboard.writeText(directURL)
        .then(() => toast.success('URL successfully copied to clipboard!'))
        .catch(err => toast.error('Failed to copy URL:', err));
    } else {
      console.error('Clipboard copy feature is not supported in this browser.');
    }
  }

  const getDirectDownloadLink = (shareLink: string) => {
    const match = shareLink.match(/https:\/\/drive\.google\.com\/file\/d\/([^/]+)\/view/g);
    if (match) {
      const fileId = match[0].match(/https:\/\/drive\.google\.com\/file\/d\/([^/]+)\/view/)[1];
      const directDownloadLink = `https://drive.google.com/uc?id=${fileId}&export=download`;
      console.log("Direct download link:", directDownloadLink);

      return directDownloadLink;
    }
    return null;
  };

  const copyImageToClipboard = async () => {
    try {
      //  image element
      const img = document.createElement('img');
      img.src = qrCode ? qrCode : '/qrcode_example.png';

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject; // Handle image load error
      });

      //canvas element
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      context.drawImage(img, 0, 0);

      const dataUrl = canvas.toDataURL('image/png');  // Get the image data URL

      //copy to clipboard
      await navigator.clipboard.write([
        new ClipboardItem({
          'image/png': fetch(dataUrl).then(res => res.blob())
        })
      ]);

      toast.success('Image Copied to Clipboard!');
    } catch (error) {
      console.error('Failed to Copy Image to Clipboard', error);
      toast.error('Failed to Copy Image to Clipboard');
    }
  };


  // Detect if user paste Google Drive link
  useEffect(() => {
    const directDownloadLink = getDirectDownloadLink(inputText);
    if (directDownloadLink !== null) {
      QRCode.toDataURL(
        directDownloadLink,
        {
          width: selectedSize,
          margin: 1,
        },
        (err, newText) => {
          if (err) return console.error(err);
          setQrCode(newText);
        }
      );
      setIsGoogleDriveLink(true);
    } else {
      setIsGoogleDriveLink(false);
    }
  }, [inputText, selectedSize]);

  return (
    <div className="w-full px-6 my-2">
      <div className="w-full flex items-center font-bold mb-3">QR Code Generator</div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <div className="flex gap-2 items-center mb-2">
            <div>Content:</div>
            <button className="btn btn-xs  mr-1" onClick={handlePasteFromClipboard}>
              Clipboard
            </button>
            <button className="btn btn-xs  mr-1" onClick={handleSampleFromJson}>
              Sample
            </button>
            <button className="btn btn-xs  mr-1" onClick={handleClear}>
              Clear
            </button>
            <select className="select select-bordered select-xs " value={selectedTemplate} onChange={handleInputChange}>
              {Object.keys(templates).map((template) => (
                <option key={template} value={template} className="btn btn-sm mr-2">
                  {template}
                </option>
              ))}
            </select>
            <button className="btn btn-xs  mr-1" onClick={handleCopyURL}>
              Copy Direct URL📋
            </button>

            {
              isGoogleDriveLink === true && (
                <div className="btn btn-xs ml-2">
                  <CopyButton text={getDirectDownloadLink(inputText)} />
                </div>
              )
            }
          </div>

          <textarea
            className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh] text-white"
            placeholder="Enter text here"
            onChange={handleInputChange}
            value={inputText}
          />
          <div className="relative flex justify-end">Input String Length: {inputText.length} bytes</div>
        </div>

        <div className="flex flex-col gap-8 items-center justify-center place-self-center">
          {/* Show direct download for Google Drive link */}
          {
            isGoogleDriveLink === true && (
              <div className="aspect-square h-[37vh] max-h-[78.5vh] relative">
                <img src={qrCode ? qrCode : "/qrcode_example.png"} className="aspect-square rounded bg-white" alt="" width={"100%"} />
                <div className="absolute inset-0 flex items-center justify-center">
                  <SvgGoogleDrive width={50} height={50} />
                </div>
              </div>
            )
          }

          {/* Normal QR code */}
          {
            isGoogleDriveLink === false && (
              <div className="aspect-square h-[37vh] max-h-[78.5vh]">
                <img src={qrCode ? qrCode : "/qrcode_example.png"} className="aspect-square rounded bg-white" alt="" width={"100%"} />
              </div>
            )
          }
          <div>
            <p>Select Size:</p>
            <select className="bg-gray-900 text-white p-2 rounded" value={selectedSize} onChange={(e) => setSelectedSize(Number(e.target.value))}>
              {sizeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="flex gap-4 ml-14">
            <a href={qrCode ? qrCode : "/qrcode_example.png"} download={`qrcode_${selectedSize}.png`} className="btn btn-sm">
              Save QR as PNG
            </a>
          <button onClick={copyImageToClipboard} className="btn btn-sm">Copy Image to Clipboard</button>
          </div>

        </div>
      </div>
    </div>
  );
}


const SvgGoogleDrive = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Google Drive"
    viewBox="0 0 512 512"
    {...props}
  >
    <rect width={512} height={512} fill="#fff" rx="15%" />
    <path fill="#4587f4" d="M468 299H112v120h288" />
    <path fill="#1da261" d="M187 51 44 299l68 120L325 51" />
    <path fill="#ffcf48" d="m325 51 143 248H331L187 51" />
  </svg>
)

