import * as React from "react";

interface DeleteButtonProps {
  onDelete: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onDelete }) => (
  <button onClick={onDelete} style={{ background: "none", border: "none", padding: 5 }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      fill="none"
      viewBox="-6.48 -6.48 36.96 36.96"
    >
      <rect
        width={36.96}
        height={36.96}
        x={-6.48}
        y={-6.48}
        fill="#0f0f24"
        strokeWidth={0}
        rx={6.283}
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.1 5H3a1 1 0 0 0 0 2h1.06l.825 13.187A3 3 0 0 0 7.879 23h8.242a3 3 0 0 0 2.994-2.813L19.94 7H21a1 1 0 1 0 0-2h-4.1a5.002 5.002 0 0 0-9.8 0Zm2.07 0h5.66a3.001 3.001 0 0 0-5.66 0Zm8.766 2H6.064l.817 13.062a1 1 0 0 0 .998.938h8.242a1 1 0 0 0 .998-.938L17.936 7Zm-3.657 3.01a1 1 0 0 1 .928 1.067l-.419 5.985a1 1 0 1 1-1.995-.14l.419-5.984a1 1 0 0 1 1.067-.928Zm-4.558 0a1 1 0 0 1 1.067.928l.419 5.985a1 1 0 0 1-1.995.14l-.419-5.986a1 1 0 0 1 .928-1.067Z"
        clipRule="evenodd"
      />
    </svg>
  </button>
);

export default DeleteButton;