import React, { useEffect, useState } from "react";
import { ulid } from "ulid";
import toast from "react-hot-toast";

const generatedULID = ulid();
console.log(generatedULID);

const typeULID = [
  { label: "ULID", value: "ULID" },
];

export default function ULIDGenerator() {
  const [ulidType, setUlidType] = useState(typeULID[0].value);
  const [quantity, setQuantity] = useState(1);
  const [generatedULIDs, setGeneratedULIDs] = useState([]);

  const generate = () => {
    const newULIDs = Array.from({ length: quantity }, () =>
      ulid()
    );
    setGeneratedULIDs(newULIDs);
  };

  const copyToClipboard = () => {
    const ulidsToCopy = generatedULIDs.join("\n");
    navigator.clipboard.writeText(ulidsToCopy);
    toast.success("Success Copy to Clipboard");
  };

  const clearULIDs = () => {
    setGeneratedULIDs([]);
  };

  // Generate on load
  useEffect(() => {
    const newULIDs = Array.from({ length: 1 }, () =>
      ulid()
    );
    setGeneratedULIDs(newULIDs);
  }, [ulidType]);

  return (
    <div>
      <div className="h-screen flex">
        <div className="w-full px-6 my-2">
          <div className="w-full flex items-center font-bold mb-3">
            ULID Generator
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="flex">
              <p>Generate: </p>
              <div className="ml-2 flex gap-2 items-center mb-3">
                <button className="btn btn-xs" onClick={generate}>
                  Generate
                </button>
                <button className="btn btn-xs" onClick={clearULIDs}>
                  Clear
                </button>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <select
                className="select select-bordered select-xs"
                value={ulidType}
                onChange={(e) => setUlidType(e.target.value)}
              >
                {typeULID.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
              <p className="btn-xs" onClick={clearULIDs}>
                X
              </p>
              <input
                type="number"
                name="valuetotimes"
                value={quantity}
                min={1}
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                className="bg-gray-900 w-14 text-center rounded-md border border-gray-700 focus:border-blue-500 text-white"
              />
              <button className="btn btn-xs" onClick={copyToClipboard}>
                Copy
              </button>
            </div>
          </div>
          <div>
            <div>
              <textarea
                value={generatedULIDs.join("\n")}
                className="bg-gray-900 min-h-[82vh] w-full p-4 border border-gray-700 overflow-y-auto rounded-md focus:outline-none focus:ring focus:border-blue-500 max-h-[70vh]"
                readOnly
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
