import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {createContext, useContext} from 'react';
import {RootStore} from './stores/RootStore';

// Create a context for the RootStore
const StoreContext = createContext(RootStore);

export const useStore = () => useContext(StoreContext);

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={RootStore}>
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
