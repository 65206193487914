import React, { useEffect, useState } from "react";
import { v4 as uuidv4, v1 as uuidv1 } from "uuid";
import toast from "react-hot-toast";

const typeUUID = [
  { label: "UUIDv1", value: "UUIDv1" },
  { label: "UUIDv4", value: "UUIDv4" },
];

export default function UUIDGenerator() {
  const [uuidType, setUuidType] = useState(typeUUID[1].value);
  const [quantity, setQuantity] = useState(1);
  const [generatedUUIDs, setGeneratedUUIDs] = useState([]);

  const generate = () => {
    const newUUIDs = Array.from({ length: quantity }, () =>
      uuidType === "UUIDv1" ? uuidv1() : uuidv4()
    );
    setGeneratedUUIDs(newUUIDs);
  };

  const copyToClipboard = () => {
    const uuidsToCopy = generatedUUIDs.join("\n");
    navigator.clipboard.writeText(uuidsToCopy);
    toast.success("Success Copy to Clipboard");
  };

  const clearUUIDs = () => {
    setGeneratedUUIDs([]);
  };

  // Generate on load
  useEffect(() => {
    const newUUIDs = Array.from({ length: 1 }, () =>
      uuidType === "UUIDv1" ? uuidv1() : uuidv4()
    );
    setGeneratedUUIDs(newUUIDs);
  }, [uuidType]);

  return (
    <div>
      <div className="h-screen flex">
        <div className="w-full px-6 my-2">
          <div className="w-full flex items-center font-bold mb-3">
            UUID Generator
          </div>
          <div className="w-full flex justify-between items-center">
            <div className="flex">
              <p>Generate: </p>
              <div className="ml-2 flex gap-2 items-center mb-3">
                <button className="btn btn-xs" onClick={generate}>
                  Generate
                </button>
                <button className="btn btn-xs" onClick={clearUUIDs}>
                  Clear
                </button>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <select
                className="select select-bordered select-xs"
                value={uuidType}
                onChange={(e) => setUuidType(e.target.value)}
              >
                {typeUUID.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
              <p className="btn-xs" onClick={clearUUIDs}>
                X
              </p>
              <input
                type="number"
                name="valuetotimes"
                value={quantity}
                min={1}
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                className="bg-gray-900 w-14 text-center rounded-md border border-gray-700 focus:border-blue-500 text-white"
              />
              <button className="btn btn-xs" onClick={copyToClipboard}>
                Copy
              </button>
            </div>
          </div>
          <div>
            <div>
              <textarea
                value={generatedUUIDs.join("\n")}
                className="bg-gray-900 min-h-[82vh] w-full p-4 border border-gray-700 overflow-y-auto rounded-md focus:outline-none focus:ring focus:border-blue-500 max-h-[70vh]"
                readOnly
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
