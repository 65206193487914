import React, { useCallback, useEffect, useState } from "react";

// Function to play sound
const playErrorSound = () => {
    const audio = new Audio('sounds/error.mp3');
    audio.play();
}

export default function UptimeChecker() {
    const [targetUrl, setTargetUrl] = useState("http://localhost:9999/");
    const [isNoPayloadOnRequest, setIsNoPayloadOnRequest] = useState(true);
    const [checkInterval, setCheckInterval] = useState(1);
    const [outputLogs, setOutputLogs] = useState([
        `[${new Date().toLocaleTimeString()}] Idle`
    ]);
    const [isChecking, setIsChecking] = useState(false);
    const logLimit = 10;

    //
    const checkUrlStatus = useCallback(async () => {
        // current logs 
        let currentLogs = [...outputLogs];

        // 
        try {
            const response = await fetch(targetUrl);
            const status = response.status;
            if (status !== 200) {
                playErrorSound();
                currentLogs.push(`[${new Date().toLocaleTimeString()}] Error: ${status}`);
            } else {
                currentLogs.push(`[${new Date().toLocaleTimeString()}] Success: ${status}`);
            }
        } catch (error) {
            playErrorSound();
            currentLogs.push(`[${new Date().toLocaleTimeString()}] Error: ${error.message}`);
        }

        // Trim logs
        if (currentLogs.length > logLimit) {
            currentLogs = currentLogs.slice(currentLogs.length - logLimit);
        }
        setOutputLogs(currentLogs);
    }, [targetUrl, outputLogs]);

    useEffect(() => {
        let intervalId;
        if (isChecking) {
            intervalId = setInterval(checkUrlStatus, checkInterval * 1000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [isChecking, checkUrlStatus, checkInterval]);


    return (
        <div className="w-full px-6 my-2">
            <div className="w-full flex items-center font-bold mb-3">
                Uptime Checker
            </div>
            <div>
                <div className="my-4">
                    <p className="font-bold mb-3">URL</p>
                    <div className="flex">
                        <div>
                            <select className="btn mr-2">
                                <option value="GET">GET</option>
                                <option value="HEAD">HEAD</option>
                                <option value="POST">POST</option>
                            </select>
                        </div>
                        <div className="grow">
                            <input
                                type="text"
                                value={targetUrl}
                                placeholder="Enter your text here..."
                                className="bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
                                onChange={(event) => {
                                    setTargetUrl(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-2">


                <div>
                    <div>
                        {/* Payload */}
                        <div>
                            <div className="grid grid-cols-2 items-center">
                                <div className="">
                                    <p className="font-bold mb-3">Payload:</p>
                                </div>
                                <div className="flex justify-end">
                                    <label className="label cursor-pointer ">
                                        <span className="label-text mr-2">No Payload</span>
                                        <input
                                            type="checkbox"
                                            checked={isNoPayloadOnRequest}
                                            className="checkbox checkbox-xs"
                                            onChange={() => setIsNoPayloadOnRequest(!isNoPayloadOnRequest)}
                                        />
                                    </label>
                                </div>
                            </div>

                            <textarea
                                className={`${isNoPayloadOnRequest ? 'opacity-30' : ''} bg-gray-900 w-full px-4 py-3 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500  text-white `}
                                placeholder='{"test": true}'
                                disabled={isNoPayloadOnRequest}
                            />
                        </div>


                        {/* Interval */}
                        <div>
                            <div className="grid grid-cols-2 items-center">
                                <p className="font-bold mb-3">Interval:</p>
                            </div>

                            <select
                                className="btn mr-2 w-full"
                                value={checkInterval}
                                onChange={(e) => setCheckInterval(Number(e.target.value))}
                            >
                                <option value="1">1 sec</option>
                                <option value="5">5 secs</option>
                            </select>
                        </div>

                        {/* Main Control Button */}
                        <div className="mt-6">
                            <button
                                className={`btn btn-${isChecking ? 'danger' : 'primary'} btn-sm`}
                                onClick={() => setIsChecking(prevState => !prevState)}
                            >
                                {isChecking ? 'Stop' : 'Start'}
                            </button>
                        </div>

                    </div>
                </div>


                <div className="flex flex-col ">
                    <div>
                        <p className="font-bold mb-3">Log:</p>
                    </div>
                    <div className="bg-gray-600 p-2 rounded-md">
                        {[...outputLogs].reverse().map((log, index) => {
                            const isStatus200 = log.includes("Success: 200");
                            const logClassName = isStatus200 ? 'text-green-500' : '';
                            return <div key={index} className={logClassName}>{log}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div >
    );
}
