// RootStore
import {LlmStore} from "./LlmStore";
import {SidebarStore} from "./SidebarStore";

export const RootStore = (() => {
    const sidebarStore = new SidebarStore();
    const llmStore = new LlmStore();
    return {
        sidebarStore,
        llmStore,
    };
})();
