import React, {useState, useCallback, useRef, useEffect} from "react";

// Fungsi untuk memutar suara error
const playErrorSound = () => {
  const audio = new Audio("sounds/error.mp3");
  audio.play();
};

const MultiUptimeChecker = () => {
  const [urls, setUrls] = useState([
    {url: "http://localhost:9999/", method: "GET", name: "API 1"},
    {
      url: "https://cat-fact.herokuapp.com/facts",
      method: "GET",
      name: "API 2",
    },
  ]);
  const [isChecking, setIsChecking] = useState(false);
  const [results, setResults] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const intervalRef = useRef(null);

  // Fungsi untuk mengecek status URL
  const checkUrlsStatus = useCallback(async () => {
    let currentResults = [];

    await Promise.all(
      urls.map(async ({url, method, name}) => {
        try {
          const startTime = performance.now();
          const response = await fetch(url, {method});
          const status = response.status;
          const endTime = performance.now();
          const responseTime = Math.round(endTime - startTime);

          if (status !== 200) {
            playErrorSound(); // Putar suara jika status tidak 200
            currentResults.push({
              url,
              status,
              responseTime,
              result: "error",
              method,
              name,
            });
          } else {
            currentResults.push({
              url,
              status,
              responseTime,
              result: "success",
              method,
              name,
            });
          }
        } catch (error) {
          playErrorSound(); // Putar suara jika terjadi kesalahan fetch
          currentResults.push({
            url,
            status: 502,
            responseTime: "-",
            result: "error",
            method,
            name,
          });
        }
      })
    );

    setResults(currentResults);
  }, [urls]);

  useEffect(() => {
    if (isChecking) {
      checkUrlsStatus();
      intervalRef.current = setInterval(checkUrlsStatus, 5000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isChecking, checkUrlsStatus]);

  const handleStartStop = () => {
    setIsChecking((prev) => !prev);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert("URL copied to clipboard!");
      },
      (err) => {
        console.error("Failed to copy URL: ", err);
      }
    );
  };

  const handleResultClick = (url) => {
    setSelectedUrl(url);
  };

  const renderSettingsView = () => (
    <div className="">
      <div className="grid grid-cols-1 gap-2">
        <div className="flex items-center space-x-2 text-white mb-2">
          <span className="w-1/6">Method</span>
          <span className="w-2/4">URL</span>
          <span className="w-1/6 text-left ml-4">Name</span>
        </div>
        {urls.map((urlObj, index) => (
          <div
            key={index}
            className="flex items-center space-x-2 bg-gray-700 p-2 rounded"
          >
            <select
              className="select select-bordered w-1/6"
              value={urlObj.method}
              onChange={(e) => {
                const newUrls = [...urls];
                newUrls[index].method = e.target.value;
                setUrls(newUrls);
              }}
            >
              <option value="GET">GET</option>
              <option value="HEAD">HEAD</option>
              <option value="POST">POST</option>
            </select>
            <input
              type="text"
              value={urlObj.url}
              placeholder="Enter URL here..."
              className="w-2/4 input input-bordered"
              onChange={(e) => {
                const newUrls = [...urls];
                newUrls[index].url = e.target.value;
                setUrls(newUrls);
              }}
            />
            <input
              type="text"
              value={urlObj.name}
              placeholder="Enter API name..."
              className="input input-bordered w-1/6"
              onChange={(e) => {
                const newUrls = [...urls];
                newUrls[index].name = e.target.value;
                setUrls(newUrls);
              }}
            />
            <button
              className="p-2 rounded ml-2 bg-gray-800"
              onClick={() => setUrls(urls.filter((_, i) => i !== index))}
            >
              <img src="/icons/delete.svg" alt="Delete" className="w-6 h-6" />
            </button>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <button
          className="btn btn-neutral"
          onClick={() =>
            setUrls([...urls, {url: "", method: "GET", name: ""}])
          }
        >
          Add New URL
        </button>
        <button
          className={`${isChecking ? "btn-error" : "btn-primary"} btn ml-2`}
          onClick={handleStartStop}
        >
          {isChecking ? "Stop Checking" : "Start Checking"}
        </button>
      </div>
    </div>
  );

  const renderCopyUrlView = () => (
    <div className="">
      <textarea
        className="textarea textarea-bordered w-full h-32"
        value={selectedUrl}
        onChange={(e) => setSelectedUrl(e.target.value)}
        placeholder="URL to copy"
      ></textarea>
      <div className="flex items-center justify-start mt-4">
        <button
          className="btn"
          onClick={() => copyToClipboard(selectedUrl)}
        >
          Copy URL
        </button>
        <p className="text-white ml-6">
          You can directly access the address to restore the dashboard
        </p>
      </div>
    </div>
  );

  return (
    <div className="flex w-full h-full bg-gray-800">
      <div className="w-full p-4">
        <h1 className="text-xl font-bold mb-4 text-white">
          Multi Uptime Checker
        </h1>
        {/* Display API check results */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          {results.map((result, index) => {
            let bgColor = "bg-gray-800"; // Default color
            if (result.result === "success") {
              bgColor =
                result.responseTime < 300 ? "bg-green-700" : "bg-yellow-500";
            } else if (result.result === "error") {
              bgColor = "bg-red-700";
            }

            return (
              <div
                key={index}
                className={`p-4 rounded shadow ${bgColor}`}
                onClick={() => handleResultClick(result.url)} // Update selected URL and switch tab
              >
                <h2 className="font-bold text-white">
                  {result.name} ({result.status})
                </h2>
                <p className="text-2xl text-white">{result.responseTime} ms</p>
                <p className="text-gray-400">
                  {result.method} {result.url}
                </p>
              </div>
            );
          })}
        </div>


        {/* Settings */}
        <div className="mt-4">
          <div role="tablist" className="tabs tabs-lifted w-full">
            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Settings"
              defaultChecked
            />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              {renderSettingsView()}
            </div>

            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Copy URL"
            />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              {renderCopyUrlView()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiUptimeChecker;
