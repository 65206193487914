import React, { useState, useEffect } from "react";
import { openDB } from "idb";
import { v4 as uuidv4 } from "uuid";

const databaseName = "devtool";
const tableName = "config";
const key = "notepad";
const TrashIcon = '/icons/trash-bin.svg';
const EditIcon = '/icons/pencil.svg';

function Notepad() {
  const [db, setDb] = useState<any>(null);
  const [files, setFiles] = useState<{ [id: string]: any }>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState<string | null>(null);
  const [text, setText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileToDeleteIndex, setFileToDeleteIndex] = useState<string | null>(null);
  const [editingFileIndex, setEditingFileIndex] = useState<string | null>(null); 
  const [editedTitle, setEditedTitle] = useState<string>("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    const initDB = async () => {
      const database = await openDB(databaseName, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(tableName)) {
            db.createObjectStore(tableName, { keyPath: 'key' });
          }
        },
      });
      
      const savedFiles = await database.get(tableName, key);

      if (savedFiles) {
        setFiles(savedFiles.data || {});
        if (Object.keys(savedFiles.data).length > 0) {
          selectFile(Object.keys(savedFiles.data)[0], savedFiles.data[Object.keys(savedFiles.data)[0]].content)
        }
      }
      setDb(database);
    };
    initDB();
  }, []);

  const selectFile = (index: string, content: string) => {
    setSelectedFileIndex(index);
    setText(content);
  };

  const handleAddFile = async () => {
    const today = new Date();
    const id = uuidv4();
    const newFile = {
      id: id,
      name: `${today.toISOString().split("T")[0].replace(/-/g, "")}_untitled_${Object.keys(files).length + 1}.md`,
      content: "",
      created: today.toISOString(),
    };
    
    const updatedFiles = {
      [id]: newFile,
      ...files,
    }

    await db.put(tableName, {key, data: updatedFiles });
    setFiles(updatedFiles)
    selectFile(id, newFile.content)

  };

  const handleTextChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (selectedFileIndex === null) return;

    const updatedFiles = {
      ...files,
      [selectedFileIndex]: { ...files[selectedFileIndex], content: e.target.value},
    };

    setFiles(updatedFiles);
    setText(e.target.value);
    await db.put(tableName, {key, data: updatedFiles });
  };

  const handleDeleteFile = async () => {
    if (fileToDeleteIndex === null) return;

    const { [fileToDeleteIndex]: _, ...updatedFiles } = files;
    setFiles(updatedFiles);
    setIsModalOpen(false);

    if (fileToDeleteIndex === selectedFileIndex) {
      setSelectedFileIndex(null);
      setText("")
    }

    await db.put(tableName, { key, data: updatedFiles });
  };

  const handleEditSave = async () => {
    if (editingFileIndex === null) return;

    const updatedFiles = {
      ...files,
      [editingFileIndex]: { ...files[editingFileIndex], name: editedTitle},
    };

    setFiles(updatedFiles);
    setEditingFileIndex(null);
    setEditedTitle("");
    setIsEditModalOpen(false);
    await db.put(tableName, {key, data: updatedFiles });
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full px-6 my-2">
        <h1 className="w-full flex items-center font-bold px-2 mt-4 mb-3">Notepad</h1>
      </div>

      <div className="flex mb-6 px-2">
        <div className="w-full md:w-1/3 px-4 my-2 flex flex-col">
          <div className="flex justify-start items-center mb-2">
            <button className="btn btn-xs mx-1">Export</button>
            <button className="btn btn-xs mx-1" onClick={handleAddFile}>
              Add Note
            </button>
          </div>
          <ul className="bg-gray-900 w-full border border-gray-700 rounded-md overflow-y-auto min-h-[78.5vh] max-h-[78.5vh] flex-1">
            {Object.keys(files).map((fileindex) => (
              <li
                key={fileindex}
                className={`p-2 cursor-pointer flex justify-between items-center ${fileindex === selectedFileIndex ? "bg-gray-700" : "bg-gray-800"}`}
                onClick={() => selectFile(fileindex, files[fileindex].content)}
              >
                <span>{files[fileindex].name}</span>
                <div className="flex items-center">
                  <button className="ml-2 bg-blue-500 text-white p-1 rounded" onClick={(e) => {
                      e.stopPropagation();
                      setEditingFileIndex(fileindex);
                      setEditedTitle(files[fileindex].name);
                      setIsEditModalOpen(true);
                    }}>
                    <img src={EditIcon} alt="Edit Icon" className="w-4 h-4" />
                  </button>
                  <button className="ml-2 bg-red-500 text-white p-1 rounded" onClick={(e) => {
                      e.stopPropagation();
                      setFileToDeleteIndex(fileindex);
                      setIsModalOpen(true);
                    }}>
                    <img src={TrashIcon} alt="Trash Bin" className="w-4 h-4" />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-full md:w-2/3 px-4 my-2 mt-10 flex flex-col">
          <textarea
            className="bg-gray-900 w-full px-6 py-2 border border-gray-700 rounded-md flex-1 min-h-[78.5vh] max-h-[78.5vh] text-white"
            placeholder="Write your notes here..."
            value={text}
            onChange={handleTextChange}
            disabled={selectedFileIndex === null}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal modal-open">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Confirm Deletion</h3>
              <p className="py-4">Are you sure you want to delete this note?</p>
              <div className="modal-action">
                <button className="btn" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </button>
                <button className="btn btn-error" onClick={handleDeleteFile}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isEditModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal modal-open">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Edit Note Title</h3>
              <input
                type="text"
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                className="input input-bordered w-full mt-4"
              />
              <div className="modal-action">
                <button className="btn" onClick={() => setIsEditModalOpen(false)}>
                  Cancel
                </button>
                <button className="btn btn-success" onClick={handleEditSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Notepad;
