import { useState, useEffect, useCallback } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "codemirror";
import { EditorState } from "@codemirror/state";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { jsonLanguage } from "@codemirror/lang-json";
import CopyButton from "../components/CopyButton";

import sampleJSON from "../assets/json/sample.json";

export default function JsonFormatter() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [invalidJson, setInvalidJSON] = useState("");
  const [spaces, setSpaces] = useState(2);

  useEffect(() => {
    if (inputText.trim() === "") {
      setOutputText("");
      setInvalidJSON("");
      return;
    }

    try {
      const formattedJSON = JSON.stringify(JSON.parse(inputText), null, spaces);
      setOutputText(formattedJSON);
      setInvalidJSON("");
    } catch (error) {
      setOutputText(inputText);
      setInvalidJSON("Invalid JSON");
    }
  }, [inputText, spaces, invalidJson]);

  const handleInputChange = useCallback((value, viewUpdate) => {
    setInputText(value);
  }, []);

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error reading from clipboard", error);
    }
  };

  const handleSampleFromJson = async () => {
    setInputText(JSON.stringify(sampleJSON, null, 2));
  };

  const handleClear = async () => {
    setInputText("");
  };


  const copyOutputToInput = () => {
    setInputText(outputText);
   };
  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">JSON Formatter</div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex mb-2">
              <div className="mb-2 inline-block">Input:</div>
              <div className="inline-block ml-3">
                <button className="btn btn-xs  mr-1" onClick={handlePasteFromClipboard}>
                  Clipboard
                </button>
                <button className="btn btn-xs  mr-1" onClick={handleSampleFromJson}>
                  Sample
                </button>
                <button className="btn btn-xs  mr-1" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>

            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[78.5vh] max-h-[78.5vh]"
              placeholder="Enter text here"
              extensions={[EditorView.lineWrapping]}
              value={inputText}
              onChange={handleInputChange}
              height="78.5vh"
              theme={dracula}
            />
          </div>
          <div>
            <div className="flex justify-between items-center">
              <div className="flex gap-2 py-[5px]">
                <p>Output:</p>
                <p className="text-red-500">{invalidJson}</p>
              </div>
              <div className="flex items-center mb-1 gap-2">
                <button  className="btn btn-xs  mr-1" onClick={copyOutputToInput}>
                  Use Output as Input
                </button>
                <select className="select select-bordered select-xs" value={spaces} onChange={(e) => setSpaces(Number(e.target.value))}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                    <option key={num} value={num}>
                      {num} Spaces
                    </option>
                  ))}
                </select>
                <CopyButton text={outputText} />
              </div>
            </div>
            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh] my-1.5"
              extensions={[EditorView.editable.of(false), EditorView.lineWrapping, EditorState.readOnly.of(true), jsonLanguage]}
              theme={dracula}
              value={outputText}
              height="78.5vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
