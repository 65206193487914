import React, { useCallback, useEffect, useState } from "react";
import sampleJSON from "../assets/json/sample.json";
import json2php from "json2php";
import CopyButton from "../components/CopyButton";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "codemirror";
import { EditorState } from "@codemirror/state";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { phpLanguage } from "@codemirror/lang-php";

export default function JSONtoPHP() {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");

  const convertJSONtoPHP = (jsonString: string) => {
    try {
      const jsonData = JSON.parse(jsonString);
      const printer = json2php.make({
        linebreak: "\n",
        indent: "\t",
        shortArraySyntax: true,
      });
      const phpCode = printer(jsonData);
      return `${phpCode}`;
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  useEffect(() => {
    const phpFormat = convertJSONtoPHP(inputText);
    setOutputText(phpFormat);
  }, [inputText]);

  const handleInputChange = useCallback((value, viewUpdate) => {
    setInputText(value);
  }, []);

  const handlePasteFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      setInputText(clipboardText);
    } catch (error) {
      console.error("Error paste from clipboard: " + error);
    }
  };

  const handleSampleJSON = async () => {
    setInputText(JSON.stringify(sampleJSON, null, 2));
  };

  const handleClear = async () => {
    setInputText("");
    setOutputText("");
  };

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">
          JSON to PHP
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex mb-2">
              <div className="mb-2 inline-block">Input:</div>
              <div className="inline-block ml-3">
                <button
                  className="btn btn-xs mx-1"
                  onClick={handlePasteFromClipboard}
                >
                  Clipboard
                </button>
                <button className="btn btn-xs mx-1" onClick={handleSampleJSON}>
                  Sample
                </button>
                <button className="btn btn-xs mx-1" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>
            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[78.5vh] max-h-[78.5vh]"
              placeholder="Enter text here"
              value={inputText}
              onChange={handleInputChange}
              height="78.5vh"
              theme={dracula}
            />
          </div>
          <div>
            <div className="flex justify-between mb-2">
              <div className="mb-2">Output:</div>
              <CopyButton text={outputText} />
            </div>
            <CodeMirror
              className="bg-gray-900 w-full pb-[72vh] border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh]"
              extensions={[
                EditorView.editable.of(false),
                EditorState.readOnly.of(true),
                EditorView.lineWrapping,
                phpLanguage,
              ]}
              theme={dracula}
              value={outputText}
              height="78.5vh"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
