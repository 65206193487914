import React, { useEffect, useState } from "react";
import sampleLorem from "../assets/json/lorem.json";
import md2 from "js-md2";
import { md4, md5, sha1, sha224, sha256, sha384, sha512 } from "hash-wasm";
import CopyButton from "../components/CopyButton";
import toast from "react-hot-toast";

export default function HashGenerator() {
  const [inputText, setInputText] = useState(sampleLorem[0]);
  const [inputLength, setInputLength] = useState(0);
  const [isLowercased, setIsLowercased] = useState(false);

  const [md2Hash, setMd2Hash] = useState("");
  const [md4Hash, setMd4Hash] = useState("");
  const [md5Hash, setMd5Hash] = useState("");
  const [sha1Hash, setSha1Hash] = useState("");
  const [sha224Hash, setSha224Hash] = useState("");
  const [sha256Hash, setSha256Hash] = useState("");
  const [sha384Hash, setSha384Hash] = useState("");
  const [sha512Hash, setSha512Hash] = useState("");

  useEffect(() => {
    if (inputText.trim() === "") {
      setMd2Hash("");
      setMd5Hash("");
      setMd4Hash("");
      setSha1Hash("");
      setSha224Hash("");
      setSha256Hash("");
      setSha384Hash("");
      setSha512Hash("");
      return;
    }
    const calculateHashes = async (text) => {
      if (isLowercased) {
        setMd2Hash(await md2(text));
        setMd4Hash(await md4(text));
        setMd5Hash(await md5(text));
        setSha1Hash(await sha1(text));
        setSha224Hash(await sha224(text));
        setSha256Hash(await sha256(text));
        setSha384Hash(await sha384(text));
        setSha512Hash(await sha512(text));
      } else {
        setMd2Hash((await md2(text)).toUpperCase());
        setMd4Hash((await md4(text)).toUpperCase());
        setMd5Hash((await md5(text)).toUpperCase());
        setSha1Hash((await sha1(text)).toUpperCase());
        setSha224Hash((await sha224(text)).toUpperCase());
        setSha256Hash((await sha256(text)).toUpperCase());
        setSha384Hash((await sha384(text)).toUpperCase());
        setSha512Hash((await sha512(text)).toUpperCase());
      }
    };

    calculateHashes(inputText);
    setInputLength(inputText.length);
  }, [inputText, isLowercased]);

  const handlePasteFromClipboard = async () => {
    try {
      const clipBoard = await navigator.clipboard.readText();
      setInputText(clipBoard);
      toast.success("Paste value from Clipboard");
    } catch (error) {
      console.error("Hash error: " + error);
    }
  };

  const handleSampleFromJSON = () => {
    setInputText(sampleLorem[0]);
  };

  const handleClear = () => {
    setInputText("");
    setInputLength(0);

    setMd2Hash("");
    setMd5Hash("");
    setMd4Hash("");
    setSha1Hash("");
    setSha224Hash("");
    setSha256Hash("");
    setSha384Hash("");
    setSha512Hash("");
  };

  const handleInputChange = (e) => {
    let text = e.target.value;
    setInputText(text);
    setInputLength(text.length);
  };

  const handleCheckboxChange = () => {
    setIsLowercased((prevValue) => !prevValue);
  };

  return (
    <div className="h-screen flex">
      <div className="w-full mx-6 my-2">
        <div className="w-full flex items-center font-bold mb-3">
          Hash Generator
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="flex mb-2">
              <div className="mb-2 inline-block">Input:</div>
              <div className="inline-block ml-3">
                <button
                  className="btn btn-xs  mr-1"
                  onClick={handlePasteFromClipboard}
                >
                  Clipboard
                </button>
                <button
                  className="btn btn-xs  mr-1"
                  onClick={handleSampleFromJSON}
                >
                  Sample
                </button>
                <button className="btn btn-xs mr-1" onClick={handleClear}>
                  Clear
                </button>
              </div>
            </div>

            <textarea
              value={inputText}
              onChange={handleInputChange}
              className="bg-gray-900 overflow-auto w-full p-2 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[78.5vh] max-h-[78.5vh]"
              placeholder="Enter text here"
            />
          </div>

          <div>
            <div className="flex justify-between">
              <small>{inputLength} bytes (string)</small>
              <label className="btn btn-xs">
                <input
                  type="checkbox"
                  name=""
                  checked={isLowercased}
                  onChange={handleCheckboxChange}
                />
                lowercased
              </label>
            </div>
            
            <div>
              <div className="my-2">MD2:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="MD2 Hash"
                  value={md2Hash}
                  readOnly
                />
                <CopyButton text={md2Hash} />
              </div>

              <div className="my-2">MD4:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="MD4 Hash"
                  value={md4Hash}
                  readOnly
                />
                <CopyButton text={md4Hash} />
              </div>

              <div className="my-2">MD5:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="MD5 Hash"
                  value={md5Hash}
                  readOnly
                />
                <CopyButton text={md5Hash} />
              </div>

              <div className="my-2">SHA1:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="SHA1 Hash"
                  value={sha1Hash}
                  readOnly
                />
                <CopyButton text={sha1Hash} />
              </div>

              <div className="my-2">SHA224:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="SHA224 Hash"
                  value={sha224Hash}
                  readOnly
                />
                <CopyButton text={sha224Hash} />
              </div>

              <div className="my-2">SHA256:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="SHA256 Hash"
                  value={sha256Hash}
                  readOnly
                />
                <CopyButton text={sha256Hash} />
              </div>

              <div className="my-2">SHA384:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="SHA384 Hash"
                  value={sha384Hash}
                  readOnly
                />
                <CopyButton text={sha384Hash} />
              </div>

              <div className="my-2">SHA512:</div>
              <div className="flex items-center">
                <input
                  className="max-w-[500px] w-full input input-bordered input-sm mr-2"
                  placeholder="SHA512 Hash"
                  value={sha512Hash}
                  readOnly
                />
                <CopyButton text={sha512Hash} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
