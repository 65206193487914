import { useNavigate } from "react-router-dom";
import appList from "./app-list.json";
import sidebarBg from "./assets/sidebar-bg.svg";
import { useEffect, useRef, useState } from "react";
import { useStore } from "./index";
import { observer } from "mobx-react-lite";

// Definisikan tipe App di bagian atas
export type App = {
  label: string;
  icon: string;
  to: string;
  description: string;
  is_enabled: boolean;
  is_external: boolean;
  external_url: string;
};

const OpenedSidebar = observer(() => {
  // Constants
  const storedSelectedApps =
    parseInt(localStorage.getItem("selectedApps"), 10) || 0;

  // States
  const [search, setSearch] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [selectedApps, setSelectedApps] = useState(storedSelectedApps);
  const [activeToolCount, setActiveToolCount] = useState(0); // New state for active tool count

  // Uses
  const inputRef = useRef(null);
  const navigate = useNavigate();

  // Functions
  const enabledApps = appList.filter((app: App) => app.is_enabled);

  // Pisahkan aplikasi internal dan eksternal
  const internalApps = enabledApps.filter((app: App) => !app.is_external);
  const externalApps = enabledApps.filter((app: App) => app.is_external);

  // Gabungkan aplikasi internal dan eksternal
  const sortedApps = [...internalApps, ...externalApps];

  const filteredApps = sortedApps.filter((app: App) =>
    app.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedApps((prev) => (prev + 1) % filteredApps.length);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedApps(
        (prev) => (prev - 1 + filteredApps.length) % filteredApps.length
      );
    } else if (e.key === "Enter") {
      const selectedApp = filteredApps[selectedApps];
      if (selectedApp) {
        if (selectedApp.is_external) {
          window.open(selectedApp.external_url, "_blank"); // Untuk buka aplikasi external
        } else {
          navigate(selectedApp.to);
        }
      }
    }
  };

  const handleItemClick = (to, index, e) => {
    e.preventDefault();
    setSelectedApps(index);
    navigate(to);
  };

  // Use Effects
  useEffect(() => {
    const enabledAppsCount = appList.filter(
      (app: App) => app.is_enabled
    ).length;
    setActiveToolCount(enabledAppsCount - 1); // Atur jumlah tool yang aktif
    console.log("Active Tool Count:", enabledAppsCount - 1); // Pastikan jumlah yang benar
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedApps", selectedApps.toString());
  }, [selectedApps]);

  useEffect(() => {
    if (isFocus) {
      inputRef.current.focus();
    }
  }, [isFocus]);

  return (
    <>
      <div
        className="h-screen flex flex-col justify-between items-center w-1/4  fixed top-0 left-0 bg-gradient-to-tr from-black via-blue-700 to-purple-800 backdrop-blur-2xl bg-opacity-70 "
        style={{
          backgroundImage: `url(${sidebarBg})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="w-full h-screen flex flex-col bg-white bg-opacity-10 border border-gray-400 justify-between items-center rounded-tl-lg rounded-tb-lg backdrop-filter backdrop-blur-md  border-opacity-30">
          <div
            className="w-full h-[50px] flex justify-center items-center align-middle pt-4 pl-4 pr-2"
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <SearchBar
              isFocus={isFocus}
              inputRef={inputRef}
              setSearch={setSearch}
              setIsFocus={setIsFocus}
              activeToolCount={activeToolCount} // Pass the active tool count
            />
          </div>
          <div className="w-full h-full flex justify-center items-center">
            <AppList
              filteredApps={filteredApps}
              handleItemClick={handleItemClick}
              selectedApps={selectedApps}
            />
          </div>
          <div className="w-full h-[40px] flex justify-center items-center pb-2">
            <FeedbackButtonContainer />
          </div>
        </div>
      </div>
    </>
  );
});

export default OpenedSidebar;

const SearchBar = (props: any) => {
  const { sidebarStore } = useStore();
  return (
    <div className="flex w-full">
      <div
        className={`flex gap-1 items-center relative w-full mb-3 ps-2 text-white rounded-md ${
          props.isFocus ? "border border-white" : "border border-transparent"
        }`}
      >
        <div className="flex">
          <img src="/icons/search.svg" alt="Search" width={"24px"} />
        </div>
        <input
          ref={props.inputRef}
          type="text"
          className="bg-transparent w-full h-full px-2 py-2 focus:outline-none"
          placeholder={`Search available ${props.activeToolCount}+ tools`} // Dynamic placeholder
          onChange={(e) => props.setSearch(e.target.value)}
          onFocus={() => props.setIsFocus(true)}
          onBlur={() => props.setIsFocus(false)}
        />
      </div>
      <div
        className="w-fit h-fit border border-transparent hover:border-white rounded-lg duration-300 cursor-pointer transition ml-2"
        onClick={() => sidebarStore.toggleSidebar()}
      >
        <img src="/icons/burger.svg" alt="Search" width={"40px"} />
      </div>
    </div>
  );
};

const AppList = (props: any) => {
  return (
    <ul className="text-white h-[85vh] w-full pl-4 pr-1 overflow-y-auto">
      {props.filteredApps.length > 0 ? (
        props.filteredApps.map((app: App, index: number) => (
          <li
            key={index}
            className={`mb-1 mr-3 rounded-md cursor-pointer p-2 font-semibold font-mono flex flex-col ${
              index === props.selectedApps
                ? "bg-white bg-opacity-10 backdrop-filter backdrop-blur-md"
                : "hover:bg-white hover:bg-opacity-10 hover:backdrop-filter hover:backdrop-blur-md"
            }`}
            onClick={(e) => {
              e.preventDefault();
              if (app.is_external) {
                window.open(app.external_url, "_blank");
              } else {
                props.handleItemClick(app.to, index, e);
              }
            }}
          >
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <img src={app.icon} width="24px" alt="icon" className="me-3" />
                <span className="font-semibold">{app.label}</span>
              </div>
              {app.is_external && (
                <span className="badge bg-[rgba(255,255,255,0.2)] text-[rgba(255,255,255,0.4)] text-xs border-none">
                  External
                </span> // DaisyUI badge for external tools
              )}
            </div>

            {app.description && (
              <p className="text-[12px] text-[rgba(255,255,255,0.4)] mt-0 ml-9 text-justify">
                {app.description}
              </p>
            )}
          </li>
        ))
      ) : (
        <div className="text-center text-white">Not Found</div>
      )}
    </ul>
  );
};

const FeedbackButtonContainer = () => {
  return (
    <div className="align-middle py-1  cursor-pointer">
      <div className="flex items-center space-x-2 px-3 py-1 h-[30px] rounded-md hover:bg-gray-400 bg-gray-300 text-black text-sm md:text-sm sm:text-xs font-bold capitalize">
        <img src="/icons/feedback-button.svg" alt="Send" width={"20px"} />
        <label className="cursor-pointer" htmlFor="my_modal_6">
          Send Feedback
        </label>
      </div>
    </div>
  );
};

//
//
